<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4>
              <i class="ti-skype" /> 会议管理 | 会议记录
            </h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="edit()">新增记录</el-button>
            <!-- <form id="fm_export" method="post" action="/meet/info/export" target="_blank" style="display: inline-block; ">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="CAT" v-model="grid.sea.CAT" />
              <input type="hidden" name="LOC" v-model="grid.sea.LOC" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.CAT" placeholder="请选择分类" @change="filter" clearable>
                <el-option v-for="it in ls_cat" :key="it.ID" :label="it.VALUE" :value="it.VALUE" />
              </el-select>
              <el-select v-model="grid.sea.LOC" placeholder="请选择地点" @change="filter" clearable style="margin-left: 5px">
                <el-option v-for="it in ls_loc" :key="it.ID" :label="it.VALUE" :value="it.VALUE" />
              </el-select>
              <el-date-picker v-model="grid.sea.Days" type="daterange" @change="filter" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wGrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="CAT" label="分类" width="80" align="center" />
              <el-table-column prop="TITLE" label="标题" width="200" />
              <el-table-column prop="LOC" label="地点" width="100" />
              <el-table-column prop="HOST" label="主持人" width="80" />
              <el-table-column prop="CNT_JOIN" label="人数/缺席" width="90" align="center">
                <template slot-scope="scope">
                  {{ scope.row.CNT_JOIN }} / <span style="color: red">{{ scope.row.CNT_ABSENT }}</span>
                </template>
              </el-table-column>
              <el-table-column label="时间" width="150">
                <template slot-scope="scope" align="center">{{ scope.row.TIME | datetime('YYYY-MM-DD HH:mm')
                  }}</template>
              </el-table-column>
              <el-table-column label="图片" width="80" align="center">
                <template slot-scope="scope" v-if="scope.row.PICS">
                  <!-- <a target="_blank" :href="'/meet/info/downpic/' + scope.row.GUID">下载</a> -->
                  <el-button type="text" @click="downUrl(scope.row.GUID)">下载</el-button>
                </template>
              </el-table-column>

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="edit(scope.row)">编辑</el-button>
                  <el-button type="text" @click="Attend(scope.row)">出席</el-button>
                  <el-button type="text" @click="del(scope.row.GUID)">删除</el-button>
                </template>
              </el-table-column>

            </wGrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="edit" @on-saved="filter"></Edit>
      <Attend ref="attend" @on-saved="filter"></Attend>
    </div>
  </div>
</template>
<script>
import wGrid from "@/components/wgrid";
import jsFileDownload from 'js-file-download'
import Edit from './edit'
import Attend from './attend'
export default {
  name: "index",
  components: { wGrid, Edit, Attend },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          DUR: '',
          Days: []
        },
        ls: [],
        total: 0,
        loading: false
      },
      ls_cat: [],
      ls_loc: []
    }
  },
  created() {
    this.getDict();
    this.filter();
  },
  methods: {
    getDict() {
      let self = this;
      this.whale.remote.getCollection({
        url: '/api/School/FX/DictApi/GetList',
        data: {
          TYPE: 'MEET'
        },
        completed(its) {
          its.map(o => {
            if (o.NAME == "MEET_CAT") self.ls_cat.push(o)
            else if (o.NAME == "MEET_LOC") self.ls_loc.push(o)
          })
        }
      })
    },
    filter() {
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      let data = {
        DTS: this.grid.sea.Days ? this.grid.sea.Days[0] : '',
        DTE: this.grid.sea.Days ? this.grid.sea.Days[1] : '',
        DUR: this.grid.sea.DUR,
        CAT: this.grid.sea.CAT,
        LOC: this.grid.sea.LOC,
        PAGE_INDEX: this.grid.sea.PAGE_INDEX,
        PAGE_SIZE: this.grid.sea.PAGE_SIZE
      }
      this.whale.remote.getCollection({
        url: "/api/School/MEET/MInfoApi/GetList",
        data: data,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    exportExcel() {
      let self = this;
      if (this.grid.sea.Days.length == 0) {
        self.whale.toast.info("请选择时间范围")
        return
      }
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('DTS', this.grid.sea.Days[0] || '')
      formData.append('DTE', this.grid.sea.Days[1] || '')
      // formData.append('LOC', this.grid.sea.LOC || '')

      this.whale.remote.getExport({
        url: "/api/School/MEET/MInfo/Rec",
        data: formData,
        completed: function (its) {
          console.log('exls', its)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its, '会议管理会议记录.xls')
          self.grid.loading = false;
        }
      })
    },
    downUrl(guid) {
      this.whale.remote.getExport({
        url: "/api/School/MEET/MInfo/DownPic/" + guid,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its, '会议图片.zip')
          self.grid.loading = false;
        }
      })
    },
    edit(r) {
      this.$refs.edit.init(r);
    },
    del(g) {
      let self = this;
      this.$confirm("真的要撤消这条记录吗？", "撤消", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        self.whale.remote.getResult({
          url: '/api/School/MEET/MinfoApi/Delete',
          data: { GUID: g },
          completed() {
            self.filter();
          }
        })
      }).catch(() => { })
    },
    Attend(r) {
      this.$refs.attend.init(r)
    }
  }
}
</script>
