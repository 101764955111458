<template>
    <el-dialog :visible.sync="visible">
        <div class="">
            <!-- <span slot="title">出席</span> -->
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>出席人</b></label>

                        <div class="attendBox">
                            <div class="" v-for="(item, index) in attend" :key="index">
                                <el-button size="small" type="primary" @click="change(item)">
                                    {{ item.TEA_NAME }}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>缺席人</b></label>
                        <div class="attendBox">
                            <div class="" v-for="(item, index) in absence" :key="index">
                                <el-button size="small" type="danger" @click="change(item)">
                                    {{ item.TEA_NAME }}
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12" style="text-align: center;">
                    <el-button @click="visible = false">取消</el-button>
                    <el-button type="success" @click="submit">确定</el-button>
                </div>
            </div>

        </div>
    </el-dialog>
</template>
<script>
    export default {
        data() {
            return {
                visible: false,
                model: {
                    LST_JOIN: []
                }
            }
        },
        mounted() {

        },
        computed: {
            attend() { return this.model.LST_JOIN.filter(o => o.STA == 1) },
            absence() { return this.model.LST_JOIN.filter(o => o.STA == 2) }
        },
        methods: {
            init(mould) {
                console.log(mould)
                this.visible = true
                this.model = mould
            },
            change(o) {
                o.STA = 3 - o.STA;
            },
            submit() {
                let arr = this.model.LST_JOIN.map(o => o.ID + '|' + o.STA)
                let self = this;
                self.whale.remote.getResult({
                    url: "/api/School/MEET/MJoinApi/Sign",
                    data: {
                        GUID: self.model.GUID,
                        JOIN: arr.join(',')
                    },
                    completed() {
                        self.visible = false
                        self.$emit("on-saved");
                    }
                })
            }
        },
    }
</script>
<style lang="less" scoped>
    .attendBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        >div {
            margin: 5px;
        }
    }
</style>